import React, { useState, useEffect, useContext } from "reactn";
import { Typography, Row, Col, Modal, Button, Form, Input } from "antd";
import { Link } from "react-router-dom";
import Center from "components/Center";
import { authAPI, userAPI } from "utils/platform-apis";
import { useGlobalLoader } from "utils/global";
import { ThemeContext } from "utils/global";

export const SignIn = ({ showModal, onCancelled }) => {
  const theme = useContext(ThemeContext);

  const { setLoading } = useGlobalLoader();
  const [mode, setMode] = useState("login");
  const [message, setMessage] = useState(undefined);
  const [useUsername, setUseUsername] = useState(false);

  useEffect(() => {
    console.log("theme", theme, "username", useUsername);
    setUseUsername(theme.use_username === true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  const login = async inputs => {
    setLoading(true);
    const userData = await authAPI.login(inputs);
    if (!userData || !userData._id) {
      if (userData.message) {
        alert(userData.message);
      } else {
        alert(`Invalid account.`);
      }
    }
    setLoading(false);
  };

  const sendReset = async inputs => {
    setLoading(true);
    const { message } = await userAPI.sendResetEmail(inputs.email);
    setMessage(message);
    setLoading(false);
  };

  const signUp = async inputs => {
    setLoading(true);
    const res = await authAPI.signup({ ...inputs });
    if (!res._id) {
      alert(res.message);
    }
    setLoading(false);
  };

  return (
    <Modal visible={showModal} onCancel={onCancelled} footer={null}>
      {mode === "login" && (
        <Row type="flex">
          <Col flex="500px">
            <div>
              <Typography.Title level={3}>Sign In</Typography.Title>
              <Typography.Paragraph>Login to chat, participate in your favorite streams</Typography.Paragraph>
            </div>
            <div>
              <Form layout="vertical" name="basic" onFinish={login} hideRequiredMark>
                {!useUsername && (
                  <Form.Item label="Email" name="email" rules={[{ required: true, type: "email" }]} hasFeedback>
                    <Input type="email" />
                  </Form.Item>
                )}
                {useUsername && (
                  <Form.Item label="Username" name="username" rules={[{ required: true }]} hasFeedback>
                    <Input type="username" />
                  </Form.Item>
                )}
                <Form.Item label="Password" name="password" rules={[{ required: true }]} hasFeedback>
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block size="large">
                    Submit
                  </Button>
                  {!useUsername && (
                    <Button type="link" block size="large" onClick={() => setMode("forgot")}>
                      Forgot Password
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
            <Center>
              <Typography.Paragraph>Don't have an account yet?</Typography.Paragraph>

              <Button type="secondary" block size="large" onClick={() => setMode("signup")}>
                Sign Up Today
              </Button>
            </Center>
          </Col>
        </Row>
      )}
      {mode === "forgot" && (
        <Row type="flex" visible={false}>
          <Col flex="500px">
            <div>
              <Typography.Title level={3}>Forgot Password</Typography.Title>
              <Typography.Paragraph>Enter your email below to receive a password reset email.</Typography.Paragraph>
            </div>
            <div>
              {message && (
                <Center>
                  <Typography.Paragraph>{message}</Typography.Paragraph>
                </Center>
              )}
              <Form layout="vertical" name="basic" onFinish={sendReset} hideRequiredMark>
                {!useUsername && (
                  <Form.Item label="Email" name="email" rules={[{ required: true, type: "email" }]} hasFeedback>
                    <Input type="email" />
                  </Form.Item>
                )}
                {useUsername && (
                  <Form.Item label="Username" name="username" rules={[{ required: true }]} hasFeedback>
                    <Input type="username" />
                  </Form.Item>
                )}
                <Form.Item>
                  <Button type="primary" htmlType="submit" block size="large">
                    Send Reset Request
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Center>
              <Button type="secondary" block size="large">
                <Link
                  onClick={() => {
                    setMode("login");
                  }}
                >
                  Back to Login
                </Link>
              </Button>
            </Center>
          </Col>
        </Row>
      )}
      {mode === "signup" && (
        <Row type="flex" visible="false">
          <Col flex="500px">
            <div>
              <Typography.Title level={3}>Sign up</Typography.Title>
              <Typography.Paragraph>Please enter an email and password for your account.</Typography.Paragraph>
            </div>
            <div>
              <Form layout="vertical" name="basic" onFinish={signUp} hideRequiredMark>
                {!useUsername && (
                  <>
                    <Form.Item label="Name" name="name" rules={[{ required: true }]} hasFeedback>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{ required: true, type: "email" }]} hasFeedback>
                      <Input type="email" />
                    </Form.Item>
                  </>
                )}
                {useUsername && (
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        pattern: "^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$",
                        message: "Username must be 5-20 alphanumeric characters"
                      }
                    ]}
                    hasFeedback
                  >
                    <Input type="username" autocomplete="off" />
                  </Form.Item>
                )}
                <Form.Item label="Password" name="password" rules={[{ required: true }]} hasFeedback>
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block size="large">
                    Sign up
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Center>
              <Button type="secondary" block size="large">
                <Link
                  onClick={() => {
                    setMode("login");
                  }}
                >
                  Back to Login
                </Link>
              </Button>
            </Center>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
