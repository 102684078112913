import { fetchWrapperPortal } from "./fetch";

export const update = async (pollId, update) =>
  await fetchWrapperPortal({
    url: `polls/${pollId}`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(update)
  });

export const deletePoll = async pollId =>
  await fetchWrapperPortal({
    url: `polls/${pollId}`,
    method: `DELETE`,
    addBearer: true
  });
