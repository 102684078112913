import React from "reactn";
import { PageLoading } from "@ant-design/pro-layout";
import styled from "styled-components";

const LoadingOverlay = styled.div`
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  user-select: none;
  padding: 25vh;
  display: ${props => (props.active ? "block" : "none")};
`;

export default props => {
  return (
    <>
      {props.children}
      <LoadingOverlay active={props.loading}>
        <PageLoading />
      </LoadingOverlay>
    </>
  );
};
