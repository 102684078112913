import React, { Suspense } from "reactn";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { PageLoading } from "@ant-design/pro-layout";

import { PublicRoute, PrivateRoute, AuthRoute } from "utils/routes";
import { LoaderStore, ThemeContext } from "utils/global";
import { useEffect, useState } from "react";
import { systemAPI } from "utils/portal-apis";

// Auth Imports
const AuthLogin = React.lazy(() => import("pages/Auth/Login"));
const ResetPassword = React.lazy(() => import("pages/Auth/ResetPassword"));
const ResetWithToken = React.lazy(() => import("pages/Auth/ResetWithToken"));
const OnboardingMain = React.lazy(() => import("pages/Auth/OnboardingMain"));
const OnboardingFirstVisit = React.lazy(() => import("pages/Auth/OnboardingFirstVisit"));

// Settings
const SettingsProfile = React.lazy(() => import("pages/Settings/Profile"));
const SettingsAccount = React.lazy(() => import("pages/Settings/Account"));
const SettingsSocial = React.lazy(() => import("pages/Settings/Social"));
const SettingsSecurity = React.lazy(() => import("pages/Settings/Security"));

// Orgs
const Orgs = React.lazy(() => import("pages/Org/List"));

// Schedule
const ScheduleChannels = React.lazy(() => import("pages/Schedule/Channels"));

// Event
const EventSingle = React.lazy(() => import("pages/Event/Single"));
const EventActiveList = React.lazy(() => import("pages/Event/Active"));

// Broadcast
const BroadcastSingle = React.lazy(() => import("pages/Broadcast/Single"));
const BroadcastMy = React.lazy(() => import("pages/Broadcast/My"));

// Watch
const WatchSingle = React.lazy(() => import("pages/Watch/Single"));

// Orgs
const OrgList = React.lazy(() => import("pages/Orgs"));

// Orgs
const UserList = React.lazy(() => import("pages/User/List"));

// Uploaded Videos CRUD
const Uploads = React.lazy(() => import("pages/Uploads"));
const ViewUploadedVideo = React.lazy(() => import("pages/Videos/View"));
const ViewUploadedImage = React.lazy(() => import("pages/Images/View"));

// Exception Imports
const Exception404 = React.lazy(() => import("pages/Exception/404"));

function App() {
  const [theme, setTheme] = useState({
    logo: "/logo_light.svg",
    color: "#F0713E",
    login_image: "/logo.png",
    company_name: "HotMic",
    description: "HotMic is a social app for live TV"
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    console.log("params", params);
    if (params.has("org")) {
      systemAPI.getOrg(params.get("org")).then(t => {
        console.log("org", t);
        if (t) {
          console.log("upating theme");
          window.less
            .modifyVars({
              "@primary-color": t.color
            })
            .catch(e => console.log("Error updating theme", e));
          setTheme(t);
        } else {
          setDefaultTheme();
        }
      });
    } else {
      setDefaultTheme();
    }
    // eslint-disable-next-line
  }, []);

  const setDefaultTheme = () => {
    console.log("Updating to default theme");
    let defaultColor = "#F0713E";
    setTheme({
      ...theme,
      ...{
        logo: "/logo_light.svg",
        color: defaultColor,
        login_image: "/logo.png"
      }
    });
    window.less
      .modifyVars({
        "@primary-color": defaultColor
      })
      .catch(e => console.log("Error updating default theme", e));
  };

  return (
    <LoaderStore>
      <ThemeContext.Provider value={theme}>
        <Router>
          <Suspense fallback={<PageLoading />}>
            <Switch>
              {/* Root */}
              <PublicRoute
                exact
                path="/"
                component={() => <Redirect to={{ pathname: "/broadcast/my", search: window.location.search }} />}
              />

              {/* Auth */}
              <AuthRoute
                exact
                path="/auth"
                component={() => <Redirect to={{ pathname: "/auth/login", search: window.location.search }} />}
              />
              <AuthRoute exact path="/auth/login" component={AuthLogin} />
              <AuthRoute exact path="/auth/reset" component={ResetPassword} />
              <AuthRoute exact path="/auth/reset/:token" component={ResetWithToken} />
              <AuthRoute exact path="/auth/onboarding" component={OnboardingMain} />
              <AuthRoute exact path="/auth/onboarding/:token" component={OnboardingFirstVisit} />

              {/* Settings */}
              <PrivateRoute
                exact
                path="/settings"
                component={() => <Redirect to={{ pathname: "/settings/profile", search: window.location.search }} />}
              />
              <PrivateRoute exact path="/settings/profile" component={SettingsProfile} />
              <PrivateRoute exact path="/settings/account" component={SettingsAccount} />
              <PrivateRoute exact path="/settings/social" component={SettingsSocial} />
              <PrivateRoute exact path="/settings/security" component={SettingsSecurity} />

              {/* Schedule */}
              {/*<PrivateRoute exact path="/schedule/channels" component={ScheduleChannels} />*/}

              {/* Event */}
              <PrivateRoute
                exact
                path="/event"
                component={() => <Redirect to={{ pathname: "/schedule/events", search: window.location.search }} />}
              />
              <PrivateRoute exact path="/event/active" component={EventActiveList} />
              <PrivateRoute exact path="/event/:event" component={EventSingle} />

              {/* Broadcast/Stream */}
              <PrivateRoute
                exact
                path="/broadcast"
                component={() => <Redirect to={{ pathname: "/schedule/events", search: window.location.search }} />}
              />
              <PrivateRoute exact path="/broadcast/my" component={BroadcastMy} />
              <PrivateRoute exact path="/broadcast/:stream" component={BroadcastSingle} />

              {/* Watch Page */}
              <PublicRoute exact path="/watch/:stream" component={WatchSingle} />

              {/* Orgs */}
              <PrivateRoute exact path="/orgs/list" component={OrgList} />

              {/* Hosts */}
              <PrivateRoute exact path="/user/list" component={UserList} />

              {/* Uploaded Videos */}
              <PrivateRoute exact path="/uploads" component={Uploads} />
              <PrivateRoute exact path="/videos/:id" component={ViewUploadedVideo} />
              <PrivateRoute exact path="/images/:id" component={ViewUploadedImage} />

              {/* Exceptions */}
              <PublicRoute component={Exception404} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeContext.Provider>
    </LoaderStore>
  );
}

export default App;
