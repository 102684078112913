import React, { useContext, createContext, useState } from "reactn";
import GlobalLoader from "components/GlobalLoader";

const LoadingContext = createContext({});

export { GlobalLoader };

export const useGlobalLoader = () => {
  return useContext(LoadingContext);
};

export const LoaderStore = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

export const ThemeContext = createContext();
export const useGlobalTheme = () => {
  return useContext(ThemeContext);
};
