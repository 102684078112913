import React from "reactn";
import { Route } from "react-router-dom";
import ProLayout from "@ant-design/pro-layout";
import { Layout } from "antd";

import { useGlobalLoader, GlobalLoader, useGlobalTheme } from "utils/global";

import { defaultSettings } from "./common";

function AuthRoute({ component: Component, ...rest }) {
  const { loading } = useGlobalLoader();
  const theme = useGlobalTheme();

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <GlobalLoader loading={loading}>
            <ProLayout {...Object.assign(defaultSettings, { logo: theme.logo })}>
              <Layout>
                <Layout.Content>
                  <Component {...props} />
                </Layout.Content>
              </Layout>
            </ProLayout>
          </GlobalLoader>
        );
      }}
    />
  );
}

export default AuthRoute;
