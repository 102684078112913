import React, { getGlobal } from "reactn";
import { Route, Redirect } from "react-router-dom";
import ProLayout from "@ant-design/pro-layout";
import { Layout } from "antd";

import { useGlobalLoader, GlobalLoader, useGlobalTheme } from "utils/global";
import { authAPI, userAPI } from "utils/portal-apis";

import { defaultSettings, getMenuRoutes, RightContent } from "./common";
import { useEffect, useState } from "react";

function PrivateRoute({ component: Component, ...rest }) {
  const { loading } = useGlobalLoader();
  const [authenticated, setAuthenticated] = useState(true);

  const theme = useGlobalTheme();

  useEffect(() => {
    userAPI.refreshUser().then(result => {
      setAuthenticated(!!result && !!result._id);
    });
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        if (authenticated && authAPI.isLoggedIn()) {
          const user = getGlobal();
          if (user.show_portal_onboarding) {
            localStorage.setItem("redirectURL", rest.location.pathname);
            return <Redirect to={{ pathname: "/auth/onboarding", search: window.location.search }} />;
          }
          return (
            <GlobalLoader loading={loading}>
              <ProLayout
                {...Object.assign(defaultSettings, { logo: theme.logo })}
                route={getMenuRoutes()}
                rightContentRender={RightContent}
                isPublicPage={false}
              >
                <Layout>
                  <Layout.Content>
                    <Component {...props} />
                  </Layout.Content>
                </Layout>
              </ProLayout>
            </GlobalLoader>
          );
        } else {
          localStorage.setItem("redirectURL", rest.location.pathname);
          return <Redirect to={{ pathname: "/auth/login", search: window.location.search }} />;
        }
      }}
    />
  );
}

export default PrivateRoute;
