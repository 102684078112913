import { fetchWrapperPortal } from "./fetch";

export const find = async text => {
  console.log("find listings", text);
  return await fetchWrapperPortal({
    url: `listings/?has=${text}`,
    method: `GET`,
    addBearer: true
  });
};
